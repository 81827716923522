const TextStyles = {
	LilitaOneRegular_35: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 35,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_40: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 40,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_50: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 50,
		dropShadow: true,
		dropShadowAngle: 0.7,
		dropShadowDistance: 8,
	},
	LilitaOneRegular_60: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 60,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_70: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 70,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_holdForAuto: {
		fontFamily: "LilitaOneRegular",
		fill: "#000000",
		fontSize: 40,
	},
	LilitaOneRegular_100: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 100,
		dropShadow: true,
		dropShadowAngle: 0.7,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_Jail: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 100,
		align: "center",
		dropShadow: true,
		dropShadowAngle: 0.7,
		dropShadowDistance: 5,
	},
	LilitaOneRegular_Roll: {
		fontFamily: "LilitaOneRegular",
		fill: "#ffffff",
		fontSize: 72,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 10,
	},
	LilitaOneRegular_RemRolls: {
		fontFamily: "LilitaOneRegular",
		fill: "#3FE90A",
		fontSize: 72,
		dropShadow: true,
		dropShadowAngle: 0.6,
		dropShadowDistance: 10,
	},
};

export default TextStyles as any;
