import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import { TextField } from "@/PIXIcomponents/shared/TextField";
import TextStyles from "@/PIXIcomponents/shared/textStyles";
import { Container, Sprite, Texture } from "pixi.js";

export class UserLevel extends Container {
	private backPanel?: Sprite;
	private profileBorder?: Sprite;
	private userImage?: Sprite;
	private backPanelLevel?: Sprite;
	private currentLevel?: TextField;
	private profileImage?: HTMLImageElement;

	constructor() {
		super();
	}

	async initUserLevel() {
		this.backPanel = Sprite.from("ProfileBase");
		this.addChild(this.backPanel);

		this.profileBorder = Sprite.from("ProfileBorder");
		this.addChild(this.profileBorder);

		let userImg =
			"https://t.me/i/userpic/320/LI9N15kWAu8pOnGryPlq7HF_0Ex7fTEUrhmJ5aEcY0_QsD3FpoPIv86gqim0NOG2.svg";
		if (window.Telegram?.WebApp.initDataUnsafe.user) {
			userImg = window.Telegram?.WebApp.initDataUnsafe.user.photo_url;
		}
		if (userImg !== "") {
			this.profileImage = new Image();
			this.profileImage.style.position = "absolute";
			this.profileImage.style.maxHeight = "200px";
			this.profileImage.style.width = "auto";
			this.profileImage.style.objectFit = "cover";
			this.profileImage.style.borderRadius = "50%";
			this.profileImage.style.display = "block";

			this.profileImage.src = userImg;
			this.profileImage.onload = () => {
				if (this.backPanel) this.backPanel.visible = false;
			};
			document
				.getElementById("profilePic")
				?.appendChild(this.profileImage);
		}

		this.backPanelLevel = Sprite.from("levelBase");
		this.addChild(this.backPanelLevel);

		this.currentLevel = new TextField(
			"Level 1",
			180,
			65,
			TextStyles.LilitaOneRegular_40,
		);
		this.addChild(this.currentLevel);
		this.resize();
	}

	resize() {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);

		if (
			this.backPanel &&
			this.backPanelLevel &&
			this.currentLevel &&
			this.profileBorder
		) {
			this.backPanel.scale.set(scale, scale);
			this.backPanel.x = window.innerWidth / 2 - 630 * scale;
			this.backPanel.y = 300 * scale;

			this.profileBorder.scale.set(scale, scale);
			this.profileBorder.x = window.innerWidth / 2 - 630 * scale;
			this.profileBorder.y = 300 * scale;

			this.backPanelLevel.scale.set(scale, scale);
			this.backPanelLevel.x = this.backPanel.x;
			this.backPanelLevel.y = this.backPanel.y + 180 * scale;

			this.currentLevel.scale.set(scale, scale);
			this.currentLevel.x = this.backPanelLevel.x + 20 * scale;
			this.currentLevel.y = this.backPanelLevel.y;
		}
		if (this.profileImage) {
			this.profileImage.style.width = 200 * scale + "px";
			this.profileImage.style.height = 200 * scale + "px";
			this.profileImage.style.marginLeft =
				window.innerWidth / 2 - 620 * scale + "px";
			this.profileImage.style.marginTop = 306 * scale + "px";
		}
	}
}
