import { emit, on, EventCallback } from "@/eventBus";

//EDIT THESE WHEN SERVER CHANGES--
export const TileTypes = [
	"start",
	"coin",
	"attack",
	"piggy_bank",
	"piggy",
	"shield",
	"mentor",
	"jail",
	"slot_machine",
	"rival",
] as const;

export interface Tile {
	type: TileType;
	position: { x: number; y: number; index: number };
}

export let game = {
	gameId: 0,
	state: {
		coins: 0,
		shielded: false,
		jailed: -1,
		laps: 0,
		position: 0,
		rolls: 0,
		shieldState: {
			powerLevel: 0,
			expiresAt: "",
			inventoryCount: 0,
		},
	},
	board: {
		tiles: [] as Tile[],
	},
};
//---
export let { state, board, gameId } = game;
export let gameState = game.state;

export function convertSnakeToCamel(obj: any): any {
	if (!obj || typeof obj !== "object") return obj;
	if (Array.isArray(obj)) return obj.map(convertSnakeToCamel);
	return Object.keys(obj).reduce((acc, key) => {
		acc[key.replace(/_([a-z])/g, (_, l) => l.toUpperCase())] =
			convertSnakeToCamel(obj[key]);
		return acc;
	}, {} as any);
}

export function updateGame(newData: Partial<SnakeCaseGame>): void {
	const convertedData = convertSnakeToCamel(newData);
	console.log(convertedData);
	Object.assign(game, convertedData);
	({ state, board, gameId } = game);
	gameState = state;
	emit("gameUpdated", game);
}

export function onGameUpdate(cb: EventCallback) {
	if (game.gameId) cb(game);
	on("gameUpdated", cb);
}

//GROSS LINE

export type TileType = (typeof TileTypes)[number];

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
	? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
	: S;

export type ConvertKeysToSnakeCase<T> = T extends object
	? T extends (infer U)[]
		? U extends object
			? {
					[K in keyof U as CamelToSnakeCase<
						K & string
					>]: ConvertKeysToSnakeCase<U[K]>;
				}[]
			: T
		: {
				[K in keyof T as CamelToSnakeCase<
					K & string
				>]: ConvertKeysToSnakeCase<T[K]>;
			}
	: T;

export type SnakeCaseGame = ConvertKeysToSnakeCase<typeof game>;
