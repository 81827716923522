export type EventCallback<T = any> = (payload: T) => void;

const eventListeners: { [event: string]: EventCallback[] } = {};

export function on<T = any>(event: string, callback: EventCallback<T>): void {
	if (!eventListeners[event]) {
		eventListeners[event] = [];
	}
	eventListeners[event].push(callback);
}

export function onInit<T = any>(
	event: string,
	callback: EventCallback<T>,
): void {
	on(event, callback);
}

export function off<T = any>(event: string, callback: EventCallback<T>): void {
	if (!eventListeners[event]) return;
	eventListeners[event] = eventListeners[event].filter(
		(cb) => cb !== callback,
	);
}

export function emit<T = any>(event: string, payload: T): void {
	if (!eventListeners[event]) return;
	eventListeners[event].forEach((callback) => callback(payload));
}
