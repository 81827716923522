import { action } from "@/api";
import { eventEmitter } from "@/PIXIcomponents";
import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import { getTranslationTag } from "@/PIXIcomponents/shared/getTranslationTag";
import { TextField } from "@/PIXIcomponents/shared/TextField";
import TextStyles from "@/PIXIcomponents/shared/textStyles";
import { Container, Sprite, Text } from "pixi.js";

export class RollButton extends Container {
	private btnRollActive?: Sprite;
	private btnRollShadow?: Sprite;
	private btnLocked = false;
	private lastRolled?: Text;
	private rollText?: TextField;
	private _container?: Container;

	constructor() {
		super();
		this.initRollButton();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this._container && this.rollText) {
			this._container.alpha = closing ? 1 : 0;
			this.rollText._text.style.fontSize = closing ? 100 : 70;
			this.rollText.y = closing ? 120 : 100;
		}
		this.resize();
	}

	initRollButton() {
		this._container = new Container();
		this.addChild(this._container);

		this.btnRollShadow = Sprite.from("rollButtonShadow");
		this.btnRollShadow.x = -this.btnRollShadow.width / 2;
		this.btnRollShadow.y = 250;
		this._container.addChild(this.btnRollShadow);

		this.btnRollActive = Sprite.from("rollButton");
		this.btnRollActive.x = -this.btnRollActive.width / 2;
		this.btnRollActive.eventMode = "static";
		this.btnRollActive.cursor = "pointer";
		this.btnRollActive.on("pointerdown", () => {
			this.rollButtonClick();
		});
		this._container.addChild(this.btnRollActive);

		const highlight = Sprite.from("rollHighlight");
		highlight.x = -175;
		highlight.y = 11;

		this._container.addChild(highlight);

		const diceImage = Sprite.from("Dice");
		diceImage.x = -diceImage.width / 2;
		diceImage.y = -50;

		this.addChild(diceImage);

		this.rollText = new TextField(
			getTranslationTag("btnRoll"),
			200,
			100,
			TextStyles.LilitaOneRegular_Roll,
		);
		this.rollText.x = -100;
		this.rollText.y = 110;

		this.addChild(this.rollText);

		const holdForPlayText = new TextField(
			getTranslationTag("holdForAutoplay"),
			280,
			54,
			TextStyles.LilitaOneRegular_holdForAuto,
		);
		holdForPlayText.x = -280 / 2;
		holdForPlayText.y = 220;

		this._container.addChild(holdForPlayText);

		this.resize();
	}

	resize() {
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);

		this.scale.set(scale, scale);
		if (this.btnRollActive) {
			this.x = window.innerWidth / 2;
			this.y =
				window.innerHeight -
				this.btnRollActive.height * scale -
				120 * scale +
				(this._container?.alpha === 1 ? 0 : 210 * scale);
		}
	}

	async rollButtonClick() {
		if (this.btnLocked || !this.btnRollActive) return;

		this.btnLocked = true;
		await action("roll_dice");
		this.unlock();
	}

	unlock() {
		if (!this.btnLocked || !this.btnRollActive) return;

		this.alpha = 1;
		this.btnLocked = false;
	}
}
