import { eventEmitter, userInterface } from "@/PIXIcomponents";
import { MenuButtonTemplate } from "@/PIXIcomponents/shared/MenuButtonTemplate";

export class Friends extends MenuButtonTemplate {
	private btnFriends?: MenuButtonTemplate;
	private posX: number = 485;
	private posXMenu: number = 400;
	constructor() {
		super();
		this.initFriends();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.btnFriends) {
			this.posX = closing ? 485 : 460;
		}
		this.resize();
	}

	initFriends() {
		this.btnFriends = new MenuButtonTemplate();
		this.addChild(this.btnFriends);
		this.btnFriends.initTemplate("Friends", this.posX, this.posXMenu);
		this.btnFriends.on("pointerdown", () => {
			this.toggleFriendsPage();
		});
	}

	toggleBtnOnOff(on: boolean) {
		if (on) this.btnFriends?.turnOn();
		else this.btnFriends?.turnOff();
	}

	toggleFriendsPage() {
		if (
			userInterface.menu?.getIsOpen() &&
			userInterface.menu?.getCurrentPage() === "friends"
		) {
			eventEmitter.emit("closeMenu");
		} else {
			if (!userInterface.menu?.getIsOpen()) eventEmitter.emit("openMenu");
			eventEmitter.emit("pageFriends");
		}
	}

	resize() {
		this.btnFriends?.resize(this.posX, this.posXMenu);
	}
}
