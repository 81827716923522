import { Container } from "pixi.js";
import { Cards } from "./Buttons/Cards";
import { Map } from "./Buttons/Map";
import { Earn } from "./Buttons/Earn";
import { Friends } from "./Buttons/Friends";
import { BottomBackPanel } from "./Displays/BottomBackPanel";
import { RollButton } from "./Buttons/RollButton";
import { RemainingRolls } from "./Displays/RemainingRolls";

export class BottomComponent extends Container {
	public cards?: Cards;
	public map?: Map;
	public earn?: Earn;
	public friends?: Friends;
	private backPanel?: BottomBackPanel;
	private rollButton?: RollButton;
	private remainingRolls?: RemainingRolls;

	constructor() {
		super();
		this.initBottomComponent();
		this.on("RESIZE", this.resize.bind(this));
	}

	initBottomComponent() {
		if (this.backPanel) {
			this.backPanel.destroy();
			this.removeChildren();
		}

		if (this.backPanel) {
			this.removeChildren();
		}
		this.backPanel = new BottomBackPanel();
		this.addChild(this.backPanel);

		this.rollButton = new RollButton();
		this.addChild(this.rollButton);

		this.remainingRolls = new RemainingRolls();
		this.addChild(this.remainingRolls);

		this.cards = new Cards();
		this.addChild(this.cards);

		this.map = new Map();
		this.addChild(this.map);

		this.earn = new Earn();
		this.addChild(this.earn);

		this.friends = new Friends();
		this.addChild(this.friends);
	}

	resize() {
		this.backPanel?.resize();
		this.rollButton?.resize();
		this.remainingRolls?.resize();
		this.cards?.resize();
		this.map?.resize();
		this.earn?.resize();
		this.friends?.resize();
	}

	// unlockRollButton() {
	//   if (this.rollButton) {
	//     this.rollButton.unlock();
	//   }
	// }

	// updateRemainingValue(newValue?: number) {
	//   this.remainingRolls?.updateRemainingRolls(newValue);
	// }
}
