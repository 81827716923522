import { Container, Graphics, Text, TextStyle } from "pixi.js";
/**
 * Resizes the given text to fit inside the given width and height.
 * @param inText Text that we want to write.
 * @param width Width inside which we will scale the text into.
 * @param height Height inside which we will scale the text into.
 * @param textStyle TextStyle for the text.
 * @param isDebug Set to true, if we want to see a box inside which we're fitting the text.
 * @example
    const simpleExample = new TextField('Hello World', 180, 60, TextStyles.LilitaOneRegular_50, true);
 */
export class TextField extends Container {
	public _text: Text;
	private _width: number;
	private _height: number;
	private _hitArea?: Graphics;
	constructor(
		inText: string,
		width: number,
		height: number,
		textStyle: TextStyle,
		isDebug?: boolean,
	) {
		super();

		this._text = new Text({
			text: inText,
			style: textStyle,
		});
		this._text.anchor.set(0.5, 0.5);
		this._text.position.set(width / 2, height / 2);
		this._width = width;
		this._height = height;
		this.addChild(this._text);

		if (isDebug) {
			this._hitArea = new Graphics();
			this._hitArea.rect(0, 0, width, height);
			this._hitArea.fill("red");
			this._hitArea.alpha = 0.4;
			this.addChild(this._hitArea);
		}
		this.resize();
	}

	setText(newText: string) {
		this._text.scale.set(1);
		this._text.text = newText;
		this.resize();
	}

	resize() {
		// Scale display objects proportionally
		const scaleFactor = Math.min(
			this._height / this._text.height,
			this._width / this._text.width,
		);
		this._text.scale.set(scaleFactor);
	}
}
