import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import { Container, Sprite } from "pixi.js";

export class TopComponentPanel extends Container {
	private backPanel?: Sprite;

	constructor() {
		super();
	}

	initBackPanel() {
		this.backPanel = Sprite.from("PlaceHolder1");
		this.addChild(this.backPanel);

		this.resize();
	}

	resize() {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (this.backPanel) {
			this.backPanel.scale.set(scale, scale);
			this.backPanel.x = window.innerWidth / 2 - this.backPanel.width / 2;
			this.backPanel.y = 326 * scale;
		}
	}
}
