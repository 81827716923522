import { Container } from "pixi.js";
import { CardsPage } from "./CardsPage";
import { eventEmitter, userInterface } from "@/PIXIcomponents";

export class Menu extends Container {
	private isOpen?: boolean;
	private currentPage: string = "";
	private cardsPage?: CardsPage;

	constructor() {
		super();
		this.initMenu();
		eventEmitter.on("openMenu", () => {
			this.isOpen = true;
		});
		eventEmitter.on("closeMenu", () => {
			this.isOpen = false;
			this.turnButtonsOnOff(this.currentPage, false);
			this.currentPage = "";
		});

		eventEmitter.on("pageCards", () => {
			this.turnButtonsOnOff(this.currentPage, false);
			this.currentPage = "cards";
			this.turnButtonsOnOff(this.currentPage);
		});

		eventEmitter.on("pageMap", () => {
			this.turnButtonsOnOff(this.currentPage, false);
			this.currentPage = "map";
			this.turnButtonsOnOff(this.currentPage);
		});

		eventEmitter.on("pageEarn", () => {
			this.turnButtonsOnOff(this.currentPage, false);
			this.currentPage = "earn";
			this.turnButtonsOnOff(this.currentPage);
		});

		eventEmitter.on("pageFriends", () => {
			this.turnButtonsOnOff(this.currentPage, false);
			this.currentPage = "friends";
			this.turnButtonsOnOff(this.currentPage);
		});
	}

	turnButtonsOnOff(btnName: string, on: boolean = true) {
		switch (btnName) {
			case "cards":
				userInterface.bottomComponent?.cards?.toggleBtnOnOff(on);
				return;
			case "map":
				userInterface.bottomComponent?.map?.toggleBtnOnOff(on);
				return;
			case "earn":
				userInterface.bottomComponent?.earn?.toggleBtnOnOff(on);
				return;
			case "friends":
				userInterface.bottomComponent?.friends?.toggleBtnOnOff(on);
				return;
			default:
				return;
		}
	}

	initMenu() {
		this.cardsPage = new CardsPage();
		this.addChild(this.cardsPage);

		this.isOpen = false;
		this.currentPage = "";
	}

	getIsOpen() {
		return this.isOpen;
	}
	getCurrentPage() {
		return this.currentPage;
	}
}
