import { Container } from "pixi.js";
import { TopComponent } from "./TopComponent";
import { BottomComponent } from "./BottomComponent";
import { Menu } from "./Pages/Menu";
import { features } from "..";

export class UserInterface extends Container {
	public topComponent?: TopComponent;
	public bottomComponent?: BottomComponent;
	public menu?: Menu;

	constructor() {
		super();
		this.initUserInterace();
	}

	async initUserInterace() {
		this.topComponent = new TopComponent();
		this.addChild(this.topComponent);

		this.menu = new Menu();
		this.addChild(this.menu);

		this.bottomComponent = new BottomComponent();
		this.addChild(this.bottomComponent);

		window.addEventListener("resize", () => {
			this.resize();
		});
	}

	resize() {
		this.children.forEach((child) => {
			child.emit("RESIZE");
		});
		features.resize();
	}
}
