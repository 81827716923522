import { updateGame } from "./game";
import { processEvents } from "./eventProcessor";
import config from "./config";

const apiUrl = config.apiUrl;

export async function play() {
	const initData = window.Telegram?.WebApp.initData;
	const response = await fetch(`${apiUrl}/play`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Telegram-Auth": `tma ${initData}`,
		},
		body: JSON.stringify({
			telegram_id:
				window.Telegram?.WebApp?.initDataUnsafe?.user?.id?.toString(),
		}),
	});
	const json = await response.json();
	return json.data;
}

export async function action(action: string) {
	const initData = window.Telegram?.WebApp.initData;
	const response = await fetch(`${apiUrl}/action`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Telegram-Auth": `tma ${initData}`,
		},
		body: JSON.stringify({
			action,
		}),
	});

	const body = await response.json();
	const data = body.data;
	if (body.status) {
		await processEvents(data.events);
		updateGame({ state: data.state });
	}
}

export async function activateShield() {
	const initData = window.Telegram?.WebApp.initData;
	const response = await fetch(`${apiUrl}/action`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Telegram-Auth": `tma ${initData}`,
		},
		body: JSON.stringify({
			session_id:
				window.Telegram?.WebApp?.initDataUnsafe?.user?.id?.toString(),
			action: "activate_shield",
		}),
	});

	const body = await response.json();
	const data = body.data;
	if (body.status) {
		await processEvents(data.events);
		updateGame({ state: data.state });
	}
}
