import { Assets, Cache, crossOrigin } from "pixi.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
const manifest = {
	bundles: [
		{
			name: "fonts",
			assets: [
				{
					alias: "LilitaOneRegular",
					src: "/fonts/LilitaOneRegular.ttf",
				},
			],
		},
		{
			name: "images",
			assets: [
				{
					alias: "jail",
					src: "/images/jail.png",
				},
				{
					alias: "selected",
					src: "/images/Selected.svg",
				},
				{
					alias: "slice",
					src: "/images/slice.svg",
				},
				{
					alias: "vector18",
					src: "/images/Vector 18.svg",
				},
				{
					alias: "rollHighlight",
					src: "/images/rollHighlight.svg",
				},
				{
					alias: "base",
					src: "/images/BASE.svg",
				},
				{
					alias: "Dice",
					src: "/images/Dice.svg",
				},
				{
					alias: "Earn",
					src: "/images/EARN.svg",
				},
				{
					alias: "Friends",
					src: "/images/FRIENDS.svg",
				},
				{
					alias: "levelBase",
					src: "/images/levelBase.svg",
				},
				{
					alias: "Map",
					src: "/images/MAP.svg",
				},
				{
					alias: "Cards",
					src: "/images/CARDS.svg",
				},
				{
					alias: "PlaceHolder1",
					src: "/images/Group 338.svg",
				},
				{
					alias: "ProfileBase",
					src: "/images/profileBase.svg",
				},
				{
					alias: "ProfileBorder",
					src: "/images/profileBorder.svg",
				},
				{
					alias: "Shield",
					src: "/images/Shield.svg",
				},
				{
					alias: "timerBase",
					src: "/images/timerBase.svg",
				},
				{
					alias: "CoinBase",
					src: "/images/coinBase.svg",
				},
				{
					alias: "Coin",
					src: "/images/Coin.svg",
				},
				{
					alias: "rollButton",
					src: "/images/rollButton.svg",
				},
				{
					alias: "rollButtonShadow",
					src: "/images/rollButtonShadow.svg",
				},
				{
					alias: "dividerLeft",
					src: "/images/dividerLeft.svg",
				},
				{
					alias: "dividerMiddle",
					src: "/images/dividerMiddle.svg",
				},
				{
					alias: "dividerRight",
					src: "/images/dividerRight.svg",
				},
				{
					alias: "Highlights",
					src: "/images/HIGHLIGHTS.svg",
				},
			],
		},
		{
			name: "spritesheets",
			assets: [],
		},
		{
			name: "spines",
			assets: [],
		},
	],
};
export async function LoadThree() {
	const gltfLoader = new GLTFLoader();
	const dracoLoader = new DRACOLoader();

	dracoLoader.setDecoderPath(
		"https://cdn.jsdelivr.net/npm/three@0.170.0/examples/jsm/libs/draco/",
	);

	gltfLoader.setDRACOLoader(dracoLoader);

	const modelUrls = {
		pig: "pig.glb",
		smallTree: "tree_small.glb",
		largeTree: "tree_large.glb",
		house1: "house_type01.glb",
		house2: "house_type02.glb",
		house3: "house_type03.glb",
	};

	const loadedModels = await Promise.all(
		Object.entries(modelUrls).map(async ([key, url]) => [
			key,
			await gltfLoader.loadAsync(`assets/models/${url}`),
		]),
	);

	const models = Object.fromEntries(loadedModels);
	return models;
}

export async function LoadPixi() {
	if (import.meta.env.DEV) {
		manifest.bundles[0].assets = manifest.bundles[0].assets.map(
			(asset) => ({
				...asset,
				src: `${asset.src}?v=${Date.now()}`,
			}),
		);
	}
	const selectedLanguage = "en"; //TODO: coonect to backend
	await Promise.all([
		Assets.init({ manifest: manifest }),

		Assets.load({
			alias: selectedLanguage + ".json",
			src: "/translations/" + selectedLanguage + ".json",
		}),

		await Assets.loadBundle(["fonts", "images", "spritesheets", "spines"]),
	]);
}
