import { Container, Sprite, Text } from "pixi.js";
import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import TextStyles from "@/PIXIcomponents/shared/textStyles";
import { TextField } from "@/PIXIcomponents/shared/TextField";
import { gameState, onGameUpdate } from "@/game";
import { action } from "@/api";
import { registerEventHandler } from "@/eventProcessor";

export class Shields extends Container {
	private currentTimer?: TextField;
	private timerBase?: Sprite;
	private shields?: Sprite[];
	private _inInterval?: any;

	constructor() {
		super();
	}

	initShields() {
		this.shields = [];
		for (let i = 0; i < 3; i++) {
			const backPanel = Sprite.from("Shield");
			backPanel.alpha = 0.5;
			this.addChild(backPanel);
			this.shields?.push(backPanel);
		}
		this.timerBase = Sprite.from("timerBase");
		this.addChild(this.timerBase);

		this.currentTimer = new TextField(
			"text",
			180,
			50,
			TextStyles.LilitaOneRegular_40,
		);
		this.addChild(this.currentTimer);

		this.interactive = true; // Opt-in to interactivity
		this.cursor = "pointer";
		this.on("pointerdown", () => {
			this.activateShield();
		});

		onGameUpdate(() => {
			this.updateShields();
		});

		registerEventHandler(
			"shield_collected",
			this.updateShields.bind(this) as any,
		);

		this.resize();
	}

	async activateShield() {
		if (
			!this.shields ||
			!this.currentTimer ||
			gameState.shieldState.inventoryCount === 0 ||
			gameState.shieldState.powerLevel === 3
		)
			return;

		this.interactive = false;
		await action("activate_shield");
		this.updateShields();
		this.interactive = true;
	}
	updateShields() {
		if (!this.shields || !this.currentTimer) return;
		const currPower = gameState.shieldState.powerLevel;

		if (currPower === 0) {
			this.currentTimer.setText(
				gameState.shieldState.inventoryCount + "",
			);
		} else {
			for (let i = 0; i < currPower; i++) {
				this.shields[i].alpha = 1;
			}

			let startDate = new Date();
			const endDate = new Date(gameState.shieldState.expiresAt);
			let remTime = this.calculateTimeDifference(startDate, endDate);
			this.formatTime(remTime);
			if (this._inInterval !== null) {
				clearInterval(this._inInterval);
				this._inInterval = null;
			}
			this._inInterval = setInterval(() => {
				startDate = new Date();
				remTime = this.calculateTimeDifference(startDate, endDate);
				this.formatTime(remTime);
				if (
					remTime.days === 0 &&
					remTime.hours === 0 &&
					remTime.minutes === 0 &&
					remTime.seconds === 0 &&
					this.currentTimer
				) {
					this.currentTimer.setText(
						gameState.shieldState.inventoryCount + "",
					);
					clearInterval(this._inInterval);
					this._inInterval = null;
				}
			}, 1000);
		}
	}

	resize() {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (this.shields) {
			for (let i = 0; i < 3; i++) {
				this.shields[i].scale.set(scale, scale);
				this.shields[i].x =
					window.innerWidth / 2 + 300 * scale + i * (70 * scale);
				this.shields[i].y = 358 * scale;
			}
		}

		if (this.timerBase && this.currentTimer) {
			this.timerBase.scale.set(scale, scale);
			this.timerBase.x = window.innerWidth / 2 + 310 * scale;
			this.timerBase.y = 464 * scale;

			this.currentTimer.scale.set(scale, scale);
			this.currentTimer.x = this.timerBase.x + 20 * scale;
			this.currentTimer.y = this.timerBase.y + 7 * scale;
		}
	}

	calculateTimeDifference(
		startDate: Date,
		endDate: Date,
	): { days: number; hours: number; minutes: number; seconds: number } {
		const timeDifferenceInMilliseconds =
			endDate.getTime() - startDate.getTime();

		// Calculate days
		const daysDifference = Math.floor(
			timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24),
		);

		// Calculate remaining milliseconds after accounting for days
		const remainingMilliseconds =
			timeDifferenceInMilliseconds % (1000 * 60 * 60 * 24);

		// Calculate hours from remaining milliseconds
		const hoursDifference = Math.floor(
			remainingMilliseconds / (1000 * 60 * 60),
		);

		// Calculate remaining milliseconds after accounting for hours
		const remainingMillisecondsAfterHours =
			remainingMilliseconds % (1000 * 60 * 60);

		// Calculate minutes from remaining milliseconds
		const minutesDifference = Math.floor(
			remainingMillisecondsAfterHours / (1000 * 60),
		);

		// Calculate remaining milliseconds after accounting for minutes
		const remainingMillisecondsAfterMinutes =
			remainingMillisecondsAfterHours % (1000 * 60);

		// Calculate seconds from remaining milliseconds
		const secondsDifference = Math.floor(
			remainingMillisecondsAfterMinutes / 1000,
		);

		return {
			days: daysDifference,
			hours: hoursDifference,
			minutes: minutesDifference,
			seconds: secondsDifference,
		};
	}

	formatTime(remTime: any) {
		if (!this.currentTimer) return;
		const hours = remTime.days * 24 + remTime.hours;
		this.currentTimer.setText(
			hours + ":" + remTime.minutes + ":" + remTime.seconds,
		);
	}
}
