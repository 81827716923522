import { eventEmitter, userInterface } from "@/PIXIcomponents";
import { MenuButtonTemplate } from "@/PIXIcomponents/shared/MenuButtonTemplate";
import { Container } from "pixi.js";

export class Earn extends Container {
	private btnEarn?: MenuButtonTemplate;
	private posX: number = 275;
	private posXMenu: number = 135;
	constructor() {
		super();
		this.initEarn();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.btnEarn) {
			this.posX = closing ? 275 : 200;
		}
		this.resize();
	}

	toggleBtnOnOff(on: boolean) {
		if (on) this.btnEarn?.turnOn();
		else this.btnEarn?.turnOff();
	}

	toggleCardsPage() {
		if (
			userInterface.menu?.getIsOpen() &&
			userInterface.menu?.getCurrentPage() === "earn"
		) {
			eventEmitter.emit("closeMenu");
		} else {
			if (!userInterface.menu?.getIsOpen()) eventEmitter.emit("openMenu");
			eventEmitter.emit("pageEarn");
		}
	}

	initEarn() {
		this.btnEarn = new MenuButtonTemplate();
		this.addChild(this.btnEarn);
		this.btnEarn.initTemplate("Earn", this.posX, this.posXMenu);
		this.btnEarn.on("pointerdown", () => {
			this.toggleCardsPage();
		});
	}

	resize() {
		this.btnEarn?.resize(this.posX, this.posXMenu);
	}
}
