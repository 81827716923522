import { eventEmitter, userInterface } from "@/PIXIcomponents";
import { MenuButtonTemplate } from "@/PIXIcomponents/shared/MenuButtonTemplate";
import { Container } from "pixi.js";

export class Cards extends Container {
	private btnCards?: MenuButtonTemplate;
	private posX: number = -625;
	private posXMenu: number = -659;
	constructor() {
		super();
		this.initCards();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.btnCards) {
			this.posX = closing ? -625 : -600;
		}
		this.resize();
	}

	initCards() {
		this.btnCards = new MenuButtonTemplate();
		this.addChild(this.btnCards);
		this.btnCards.initTemplate("Cards", this.posX, this.posXMenu);
		this.btnCards.on("pointerdown", () => {
			this.toggleCardsPage();
		});
	}

	toggleBtnOnOff(on: boolean) {
		if (on) this.btnCards?.turnOn();
		else this.btnCards?.turnOff();
	}

	toggleCardsPage() {
		if (
			userInterface.menu?.getIsOpen() &&
			userInterface.menu?.getCurrentPage() === "cards"
		) {
			eventEmitter.emit("closeMenu");
		} else {
			if (!userInterface.menu?.getIsOpen()) eventEmitter.emit("openMenu");
			eventEmitter.emit("pageCards");
		}
	}

	resize() {
		this.btnCards?.resize(this.posX, this.posXMenu);
	}
}
