import { eventEmitter } from "@/PIXIcomponents";
import { Container, Sprite } from "pixi.js";

export class BottomDividers extends Container {
	// private dividerMiddle?: Sprite;
	private dividers?: Container[];
	constructor() {
		super();
		this.initBottomDividers();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}
	adaptMenuOpen(closing: boolean = false) {
		if (this.dividers) {
			if (closing) {
				this.dividers[0].x = 210;
				this.dividers[1].x = 426;
				this.dividers[2].x = 894;
				this.dividers[3].x = 1110;
			} else {
				this.dividers[0].x = 264;
				this.dividers[1].x = 528;
				this.dividers[2].x = 792;
				this.dividers[3].x = 1056;
			}
		}
	}
	initBottomDividers() {
		this.dividers = [];
		for (let i = 0; i < 4; i++) {
			const each = new Container();
			this.addChild(each);

			const dividerLeft = Sprite.from("dividerLeft");
			dividerLeft.x = -5;
			each.addChild(dividerLeft);
			const dividerRight = Sprite.from("dividerRight");
			dividerRight.x = 5;
			each.addChild(dividerRight);
			const dividerMiddle = Sprite.from("dividerMiddle");
			dividerMiddle.x = 0;
			each.addChild(dividerMiddle);

			this.dividers.push(each);
			this.dividers[i].y = this.dividers[i].height / 2 + 30;
		}

		this.dividers[0].x = 210;
		this.dividers[1].x = 426;
		this.dividers[2].x = 894;
		this.dividers[3].x = 1110;
	}
}
