import { UserInterface } from "@/PIXIcomponents/UserInterface/UserInterface";
import { Application, EventEmitter } from "pixi.js";
import { Features } from "./Features/Features";

export let eventEmitter: EventEmitter;
export let userInterface: UserInterface;
export let features: Features;

export function initPixiApp(app: Application) {
	eventEmitter = new EventEmitter();

	userInterface = new UserInterface();
	app.stage.addChild(userInterface);

	features = new Features();
	app.stage.addChild(features);
}
