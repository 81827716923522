type Event<T = any> = {
	type: string;
	data: T;
};

//default handlers
export const eventHandlers: Record<
	string,
	Array<(data: any) => Promise<Event>>
> = {};

export function registerEventHandler<T>(
	eventName: string,
	handler: (data: T) => Promise<Event<T>>,
): void {
	if (!eventHandlers[eventName]) {
		eventHandlers[eventName] = [];
	}
	eventHandlers[eventName].push(handler);
}

// Event Dependencies
const dependencies: Record<string, string[]> = {
	player_moved: ["dice_rolled"],
	coins_changed: ["player_moved", "dice_rolled"],
};

// Process Events
export async function processEvents(events: Event[]) {
	if (!events) return;
	console.log(events, eventHandlers);
	const completed = new Set<string>();
	const pending = [...events];

	while (pending.length) {
		const ready = pending.filter(
			(event) =>
				!(dependencies[event.type] || []).some(
					(dep) => !completed.has(dep),
				),
		);

		if (!ready.length) {
			console.error("Unresolvable dependencies detected:", pending);
			break;
		}

		await Promise.all(
			ready.map(async (event) => {
				const handlers = eventHandlers[event.type];
				if (handlers && handlers.length > 0) {
					await Promise.all(
						handlers.map((handler) => handler(event.data)),
					);
				} else {
					console.warn(`No handlers for event: ${event}`, event);
				}
				completed.add(event.type);
				pending.splice(pending.indexOf(event), 1);
			}),
		);
	}

	console.log("All events processed.");
}
