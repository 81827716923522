import { Container, Text } from "pixi.js";
import { gameState, onGameUpdate } from "@/game";
import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import TextStyles from "@/PIXIcomponents/shared/textStyles";
import { TextField } from "@/PIXIcomponents/shared/TextField";
import { eventEmitter } from "@/PIXIcomponents";
import { registerEventHandler } from "@/eventProcessor";

export class RemainingRolls extends Container {
	private remainingRollsValue?: number;
	private remainingRolls?: TextField;
	private totalRolls?: TextField;
	constructor() {
		super();
		this.initRemainingRolls();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.remainingRolls && this.totalRolls) {
			this.remainingRolls.visible = closing;
			this.totalRolls.visible = closing;
		}
	}

	initRemainingRolls() {
		this.remainingRollsValue = 0;
		this.remainingRolls = new TextField(
			this.remainingRollsValue + "",
			100,
			90,
			TextStyles.LilitaOneRegular_RemRolls,
		);
		this.addChild(this.remainingRolls);

		this.totalRolls = new TextField(
			"/50",
			100,
			65,
			TextStyles.LilitaOneRegular_50,
		);
		this.addChild(this.totalRolls);

		onGameUpdate(() => {
			this.updateRemainingRolls();
		});

		registerEventHandler(
			"player_moved",
			this.updateRemainingRolls.bind(this) as any,
		);

		this.resize();
	}

	resize() {
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (this.remainingRolls && this.totalRolls) {
			this.remainingRolls.scale.set(scale, scale);
			this.remainingRolls.x = window.innerWidth / 2 - 98 * scale;
			this.remainingRolls.y = window.innerHeight - 140 * scale;

			this.totalRolls.scale.set(scale, scale);
			this.totalRolls.x = window.innerWidth / 2 - 5 * scale;
			this.totalRolls.y = window.innerHeight - 125 * scale;
		}
	}
	updateRemainingRolls() {
		this.remainingRollsValue = gameState.rolls;
		if (this.remainingRolls)
			this.remainingRolls.setText(this.remainingRollsValue + "");
	}
}
