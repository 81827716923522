import { Container } from "pixi.js";
import { MenuButtonTemplate } from "@/PIXIcomponents/shared/MenuButtonTemplate";
import { eventEmitter, userInterface } from "@/PIXIcomponents";

export class Map extends Container {
	private btnMap?: MenuButtonTemplate;
	private posX: number = -409;
	private posXMenu: number = -390;
	constructor() {
		super();
		this.initMap();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.btnMap) {
			this.posX = closing ? -409 : -330;
		}
		this.resize();
	}

	initMap() {
		this.btnMap = new MenuButtonTemplate();
		this.addChild(this.btnMap);
		this.btnMap.initTemplate("Map", this.posX, this.posXMenu);
		this.btnMap.on("pointerdown", () => {
			this.toggleMapPage();
		});
	}

	toggleBtnOnOff(on: boolean) {
		if (on) this.btnMap?.turnOn();
		else this.btnMap?.turnOff();
	}

	toggleMapPage() {
		if (
			userInterface.menu?.getIsOpen() &&
			userInterface.menu?.getCurrentPage() === "map"
		) {
			eventEmitter.emit("closeMenu");
		} else {
			if (!userInterface.menu?.getIsOpen()) eventEmitter.emit("openMenu");
			eventEmitter.emit("pageMap");
		}
	}

	resize() {
		this.btnMap?.resize(this.posX, this.posXMenu);
	}
}
