import {
	Scene,
	OrthographicCamera,
	AmbientLight,
	DirectionalLight,
	MeshStandardMaterial,
	PlaneGeometry,
	Mesh,
	Vector3,
	AnimationMixer,
	Clock,
	TextureLoader,
	FrontSide,
	MeshBasicMaterial,
	InstancedMesh,
	Matrix4,
	Group,
	LoopOnce,
} from "three";

import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { registerEventHandler } from "./eventProcessor";
import { onGameUpdate, board, state, Tile, gameId, TileTypes } from "@/game";
import anime from "animejs";

let cam;
export function initScene() {
	const scene = new Scene();
	lights(scene);
	level(scene);
	dice(scene);
	pig(scene);
	floor(scene);

	const textureLoader = new TextureLoader();
	const textures = TileTypes.map((type) =>
		textureLoader.loadAsync(`images/tiles/tile_${type}.webp`),
	);
	onGameUpdate(({ board }) => {
		tiles(scene, board, textures);
	});

	return scene;
}

export function updateScene(scene: Scene, camera: OrthographicCamera) {
	const pig = scene.getObjectByName("pig");
	cam = camera;
	if (!pig || !camera || !gameId) return;

	const smoothness = 0.08;
	const desiredPosition = pointIsoCamera(pig.position);

	camera.position.x += (desiredPosition.x - camera.position.x) * smoothness;
	camera.position.z += (desiredPosition.z - camera.position.z) * smoothness;
	camera.position.y = desiredPosition.y;
}

function pointIsoCamera(targetPosition: Vector3): Vector3 {
	const offset = 8;
	const azimuth = Math.PI / 4;
	const elevation = Math.atan(1 / Math.sqrt(2));
	const fixedY = offset * Math.tan(elevation);

	return new Vector3(
		targetPosition.x + offset * Math.cos(azimuth),
		fixedY,
		targetPosition.z + offset * Math.sin(azimuth),
	);
}

function floor(scene: Scene) {
	const createFloor = (size: number, color: number, y: number) => {
		const mesh = new Mesh(
			new PlaneGeometry(size, size),
			new MeshStandardMaterial({ color }),
		);
		mesh.position.y = y;
		mesh.rotation.x = -Math.PI / 2;
		mesh.receiveShadow = true;
		scene.add(mesh);
	};

	createFloor(10, 0x94ae41, -0.1);
	createFloor(100, 0xa2794d, -0.2);
}

export function initCamera() {
	const cameraZoom = 4;
	const d = cameraZoom;
	const aspect = window.innerWidth / window.innerHeight;
	const camera = new OrthographicCamera(
		-d * aspect,
		d * aspect,
		d,
		-d,
		0.01,
		140,
	);

	camera.position.set(9999, 9999, 9999);
	camera.updateProjectionMatrix();
	window.addEventListener("resize", () => {
		const aspect = window.innerWidth / window.innerHeight;
		camera.left = -d * aspect;
		camera.right = d * aspect;
		camera.updateProjectionMatrix();
	});

	onGameUpdate(() => {
		if (camera.position.x === 9999) {
			const initState = board?.tiles[state?.position - 1];
			const desiredPosition = pointIsoCamera(
				new Vector3(
					initState.position.x - 3,
					0,
					initState.position.y - 3,
				),
			);
			camera.lookAt(0, 0, 0);
			camera.position.copy(desiredPosition);
			camera.updateProjectionMatrix();
		}
	});
	return camera;
}

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath(
	"https://cdn.jsdelivr.net/npm/three@0.170.0/examples/jsm/libs/draco/",
);
gltfLoader.setDRACOLoader(dracoLoader);

async function loadModel(url: string) {
	const model = await gltfLoader.loadAsync("models/" + url + ".glb");
	model.scene.scale.set(0.5, 0.5, 0.5);
	return model;
}

function lights(scene: Scene) {
	const ambientLight = new AmbientLight(0xffffff, 1.0);
	scene.add(ambientLight);

	const dirLight = new DirectionalLight(0xffffff, 1);
	dirLight.position.set(20, 20, 10);
	scene.add(dirLight);
}

async function level(scene: Scene) {
	const model = await loadModel("level");
	scene.add(model.scene);
}
const tileInstances = new Map();
const cardInstances = new Map();

async function tiles(
	scene: Scene,
	board: { tiles: Array<Tile> },
	textures: Promise<any>[],
) {
	const boardKey = JSON.stringify(
		board.tiles.map((t) => ({ type: t.type, position: t.position })),
	);
	if ((scene as any).lastBoardKey === boardKey) return;
	(scene as any).lastBoardKey = boardKey;

	// Remove existing tiles
	const tileMeshes = scene.children.filter(
		(child) =>
			child instanceof InstancedMesh &&
			(child.geometry instanceof RoundedBoxGeometry ||
				child.geometry instanceof PlaneGeometry),
	);

	tileMeshes.forEach((tile) => {
		scene.remove(tile);
	});
	// Create base geometries and materials
	const tileGeometry = new RoundedBoxGeometry(1.0, 0.2, 1.0, 1, 5);
	const cardGeometry = new PlaneGeometry(0.8, 0.8);
	const tileMaterial = new MeshStandardMaterial({
		color: 0xdcc18b,
		side: FrontSide,
	});

	// Group tiles by type
	const tileGroups = board.tiles.reduce(
		(groups, tile, i) => {
			(groups[tile.type] = groups[tile.type] || []).push({
				index: i,
				tile,
			});
			return groups;
		},
		{} as Record<string, Array<{ index: number; tile: Tile }>>,
	);

	Object.entries(tileGroups).forEach(([_, tiles], typeIndex) => {
		const instancedTiles = new InstancedMesh(
			tileGeometry,
			tileMaterial,
			tiles.length,
		);
		const matrix = new Matrix4();
		tiles.forEach(({ tile }, i) => {
			matrix.setPosition(tile.position.x - 3, 0.1, tile.position.y - 3);
			instancedTiles.setMatrixAt(i, matrix);
			tileInstances.set(`${tile.position.x}_${tile.position.y}`, {
				index: i,
				instancedTiles,
			});
		});
		scene.add(instancedTiles);

		const instancedCards = new InstancedMesh(
			cardGeometry,
			new MeshBasicMaterial({
				transparent: true,
				side: FrontSide,
				alphaTest: 0.1,
				depthWrite: false,
			}),
			tiles.length,
		);

		const cardMatrix = new Matrix4().makeRotationX(-Math.PI / 2);
		tiles.forEach(({ tile }, i) => {
			cardMatrix.setPosition(
				tile.position.x - 3,
				0.225,
				tile.position.y - 3,
			);
			instancedCards.setMatrixAt(i, cardMatrix);
			cardInstances.set(`${tile.position.x}_${tile.position.y}`, {
				index: i,
				instancedCards,
			});
		});

		instancedCards.material.opacity = 0;
		scene.add(instancedCards);
		textures[typeIndex].then(async (texture) => {
			(instancedCards.material as MeshBasicMaterial).map = texture;
			(instancedCards.material as MeshBasicMaterial).needsUpdate = true;
			anime({
				targets: instancedCards.material,
				opacity: 1,
				duration: 160,
				easing: "easeOutQuad",
			});
		});
	});
}

const mixers = new Map<string, AnimationMixer>();
async function playAnimation(
	model: any,
	animationName: string,
	{ playOnce }: { playOnce?: Boolean } = {},
): Promise<void> {
	const modelName = model.scene.name || "unnamed";

	if (!mixers.has(modelName)) {
		mixers.set(modelName, new AnimationMixer(model.scene));
	}
	const mixer = mixers.get(modelName)!;
	mixer.stopAllAction();
	const clip = model.animations.find((a: any) => a.name === animationName);
	const action = mixer.clipAction(clip);
	action.play();
	if (playOnce) {
		action.setLoop(LoopOnce, 1);
		action.clampWhenFinished = true;
	}
	const clock = new Clock();

	return new Promise((resolve) => {
		function animate() {
			const delta = clock.getDelta();
			mixer.update(delta);

			if (action.isRunning()) {
				requestAnimationFrame(animate);
			} else {
				console.log("DONE animating");
				resolve();
			}
		}

		animate();
	});
}

async function pig(scene: Scene) {
	const pig = await loadModel("pig");
	pig.scene.name = "pig";
	playAnimation(pig, "idle");
	scene.add(pig.scene);
	onGameUpdate(() => {
		const initState = board?.tiles[state?.position - 1];
		pig.scene.position.set(
			initState.position.x - 3,
			0.2,
			initState.position.y - 3,
		);
	});

	registerEventHandler(
		"player_moved",
		async (e: {
			from: { index: number };
			to: { index: number };
		}): Promise<any> => {
			const hopDuration = 200;
			const tilesToAnimate: number[] = [];
			let currentPos = e.from.index - 1;
			const targetPos = e.to.index - 1;

			while (currentPos !== targetPos) {
				currentPos = (currentPos + 1) % board.tiles.length;
				tilesToAnimate.push(currentPos);
			}

			playAnimation(pig, "run");
			for (const tileIndex of tilesToAnimate) {
				const targetTile = board.tiles[tileIndex];
				const nextTile =
					board.tiles[(tileIndex + 1) % board.tiles.length];

				const pTile =
					board.tiles[
						(tileIndex - 1 + board.tiles.length) %
							board.tiles.length
					];
				const data = tileInstances.get(
					`${pTile.position.x}_${pTile.position.y}`,
				);
				const cardData = cardInstances.get(
					`${pTile.position.x}_${pTile.position.y}`,
				);
				const matrix = new Matrix4();
				const cardMatrix = new Matrix4().makeRotationX(-Math.PI / 2);

				anime({
					targets: { y: 0 },
					y: [0.1, 0.05, 0.1],
					duration: 200,
					keyframes: [
						{ y: 0.1, duration: 0 },
						{ y: -0.05, duration: 50 },
						{ y: 0.1, duration: 150 },
					],
					easing: "easeInQuad",
					update: (anim) => {
						const y = +anim.animations[0].currentValue;
						matrix.makeTranslation(
							pTile.position.x - 3,
							y,
							pTile.position.y - 3,
						);
						data.instancedTiles.setMatrixAt(data.index, matrix);
						data.instancedTiles.instanceMatrix.needsUpdate = true;

						cardMatrix.setPosition(
							pTile.position.x - 3,
							y + 0.125,
							pTile.position.y - 3,
						);
						cardData.instancedCards.setMatrixAt(
							cardData.index,
							cardMatrix,
						);
						cardData.instancedCards.instanceMatrix.needsUpdate =
							true;
					},
				});
				// Calculate direction to face
				const dx = nextTile.position.x - targetTile.position.x;
				const dz = nextTile.position.y - targetTile.position.y;
				const angle = Math.atan2(dx, dz);

				// Set initial rotation before animation
				//	pig.scene.rotation.y = angle;

				await new Promise<void>((resolve) => {
					const startRotation = pig.scene.rotation.y;
					const rotationDiff =
						((angle - startRotation + Math.PI) % (2 * Math.PI)) -
						Math.PI;

					anime({
						targets: [pig.scene.position],
						x: targetTile.position.x - 3,
						z: targetTile.position.y - 3,
						//	y: [0.2, 0.2 + 0.8, 0.2],
						duration: hopDuration,
						easing: "linear",
						complete: (_): void => {
							resolve();
						},
						update: (anim) => {
							const progress = anim.progress / 100;
							pig.scene.rotation.y =
								startRotation + rotationDiff * progress;
							pig.scene.updateMatrix();
						},
					});
				});
			}

			const targetTile = board.tiles[e.to.index - 1];

			const data = tileInstances.get(
				`${targetTile.position.x}_${targetTile.position.y}`,
			);
			const cardData = cardInstances.get(
				`${targetTile.position.x}_${targetTile.position.y}`,
			);

			const glowTile = new Mesh(
				data.instancedTiles.geometry,
				data.instancedTiles.material.clone(),
			);

			const instanceMatrix = new Matrix4();
			data.instancedTiles.getMatrixAt(data.index, instanceMatrix);
			const hiddenMatrix = instanceMatrix
				.clone()
				.setPosition(0, -1000, 0);
			data.instancedTiles.setMatrixAt(data.index, hiddenMatrix);
			data.instancedTiles.instanceMatrix.needsUpdate = true;

			glowTile.position.set(
				targetTile.position.x - 3,
				0,
				targetTile.position.y - 3,
			);
			scene.add(glowTile);
			anime({
				targets: { y: 0, scale: 1 },
				y: [-0.3, 0.1],
				scale: [2.0, 1.0],
				duration: 800,
				easing: "easeOutElastic",
				update: (anim) => {
					const y = +anim.animations[0].currentValue;
					const scale = +anim.animations[1].currentValue;

					glowTile.position.set(
						targetTile.position.x - 3,
						y,
						targetTile.position.y - 3,
					);

					const hiddenMatrix = instanceMatrix
						.clone()
						.makeRotationX(-Math.PI / 2);

					hiddenMatrix.setPosition(
						targetTile.position.x - 3,
						y + 0.125,
						targetTile.position.y - 3,
					);

					cardData.instancedCards.setMatrixAt(
						cardData.index,
						hiddenMatrix,
					);

					cardData.instancedCards.instanceMatrix.needsUpdate = true;
					glowTile.scale.set(scale, 1, scale);
				},
			});

			// Temporarily replace instanced tile with individual glowing tile

			// Animate the glow
			anime({
				targets: { y: 0, scale: 1, glow: 0 },
				y: [-0.3, 0.1],
				scale: [1.5, 1.0],
				glow: [0, 1.0, 0],
				duration: 300,
				easing: "easeInOutQuad",
				loop: 2,
				update: (anim) => {
					const glow = anim.animations[2].currentValue;
					glowTile.material.emissive.setRGB(glow, glow, glow);
				},
				complete: () => {
					const hiddenMatrix = instanceMatrix.clone();
					data.instancedTiles.setMatrixAt(data.index, hiddenMatrix);
					data.instancedTiles.instanceMatrix.needsUpdate = true;
					scene.remove(glowTile);
				},
			});

			playAnimation(pig, "idle");
			return new Event("player_moved");
		},
	);
}

async function dice(scene: Scene) {
	const dice = await loadModel("dice");
	dice.scene.name = "dice";
	const diceMesh = dice.scene.children[0] as Mesh;
	diceMesh.castShadow = true;
	diceMesh.renderOrder = 1;

	const diceGroup = new Group();
	diceGroup.name = "diceGroup";
	diceGroup.position.y = -5; // Offset the group by -5 units on the Y-axis
	diceGroup.scale.set(0.5, 0.5, 0.5);
	// Add the dice model to the parent group
	diceGroup.add(dice.scene);

	// Add the parent group to the scene
	scene.add(diceGroup);

	let [rotx, roty, rotz] = [0, 0, 0];
	registerEventHandler(
		"dice_rolled",
		async ({ rolled }: { rolled: number }): Promise<any> => {
			diceMesh.geometry.rotateX(-rotx);
			diceMesh.geometry.rotateY(-roty);
			diceMesh.geometry.rotateZ(-rotz);
			const result = rolled;
			const pig = scene.getObjectByName("pig");
			let distance = 2 - Math.random() * 0.7; // Distance to move
			let angle = Math.atan2(
				pig?.position?.z || 0,
				pig?.position?.x || 0,
			);
			angle += Math.random() * 0.2;

			let targetPos = new Vector3(); // Create a new vector for the target position
			targetPos.x = distance * Math.cos(angle) + 0.4;
			targetPos.y = 5;
			targetPos.z = distance * Math.sin(angle) + 1.0;

			const rotations = [
				[0, 0, 0], // 1
				[-Math.PI / 2, 0, 0], //2
				[0, 0, -Math.PI / 2], //3
				[Math.PI / 2, 0, 0], //4
				[0, 0, Math.PI / 2], // 5
				[Math.PI, 0, 0], //6
			];
			diceGroup.position.copy(targetPos);
			[rotx, roty, rotz] = rotations[result - 1];
			diceMesh.geometry.rotateX(rotx);
			diceMesh.geometry.rotateY(roty);
			diceMesh.geometry.rotateZ(rotz);
			diceMesh.rotation.set(0, 0, 0);
			playAnimation(dice, "GOODTHROW", { playOnce: true });
		},
	);
}
