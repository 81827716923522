import { Application } from "pixi.js";
import { LoadPixi } from "@/Loading";
import { updateGame, SnakeCaseGame, onGameUpdate } from "@/game";
import { initPixiApp } from "@/PIXIcomponents";
import { WebGLRenderer } from "three"; // Import Three.js
import { initScene, initCamera, updateScene } from "@/threeScene";
import { play, action } from "@/api";
import { initMocks } from "@/mocks";
import Stats from "stats.js";
const isDev = import.meta.env.DEV;
let stats: Stats, statsInfo: HTMLDivElement;

if (isDev) initStats();
initTelegram();
initPixi();
initThree();
initMocks();
initAPI();

function initStats() {
	stats = new Stats();
	document.body.appendChild(stats.dom);
	stats.dom.style.transform = "scale(2)"; // Enlarge the stats.js display
	stats.dom.style.transformOrigin = "top left"; // Ensure proper scaling alignment
	stats.dom.style.position = "fixed"; // Keep it fixed on the screen
	stats.dom.style.zIndex = "1000"; // Ensure it's always on top
	statsInfo = document.createElement("div");
	Object.assign(statsInfo.style, {
		position: "fixed",
		top: "10px",
		right: "10px",
		fontSize: "14px",
		color: "white",
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		padding: "10px",
		zIndex: "1000",
		borderRadius: "5px",
		lineHeight: "1.5",
	});
	document.body.appendChild(statsInfo);
}

function updateStats(renderer: WebGLRenderer) {
	if (!isDev) return;
	const { calls, triangles } = renderer.info.render;
	const { geometries, textures } = renderer.info.memory;
	statsInfo.innerHTML = `Draw Calls: ${calls}<br>Triangles: ${triangles}<br>Geometries: ${geometries}<br>Textures: ${textures}`;
}

function initTelegram() {
	const webapp = window?.Telegram.WebApp;
	webapp.expand();
	if (webapp.platform !== "weba" && webapp.isVersionAtLeast("8.0")) {
		webapp.requestFullscreen();
	}
	webapp.enableClosingConfirmation();
	webapp.disableVerticalSwipes();
	webapp.ready();
}

function showRollsPopup() {
	window.Telegram.WebApp.showPopup(
		{
			title: "Out of Rolls",
			message: "Would you like to buy more rolls?",
			buttons: [
				{
					id: "buy",
					type: "default",
					text: "Buy More",
				},
				{
					id: "cancel",
					type: "cancel",
					text: "Cancel",
				},
			],
		},
		(buttonId: string) => {
			console.log(buttonId);
			if (buttonId === "buy") {
				action("purchase_rolls");
			} else {
				showRollsPopup();
			}
		},
	);
}
async function initAPI() {
	if (__USE_MOCKS__) {
		updateGame((await import("@/mocks/play")).default as SnakeCaseGame);
		return;
	}
	updateGame(await play());

	onGameUpdate(({ state }) => {
		if (state.rolls === 0) {
			showRollsPopup();
		}
	});
}

async function initPixi() {
	const app = new Application();
	await app.init({
		width: window.innerWidth,
		height: window.innerHeight,
		autoDensity: true,
		resolution: window.devicePixelRatio || 1,
		resizeTo: window,
		backgroundAlpha: 0, // Won't work without this line
	});
	app.renderer.background.alpha = 0;
	app.canvas.style.position = "absolute";
	app.canvas.style.zIndex = "2000";
	app.canvas.id = "pixiCanvas";

	document.body.appendChild(app.canvas);
	await LoadPixi();
	initPixiApp(app);
}

async function initThree() {
	const threeCanvas = document.createElement("canvas");
	threeCanvas.id = "threeCanvas";
	threeCanvas.style.position = "absolute";
	threeCanvas.style.top = "0";
	threeCanvas.style.left = "0";
	threeCanvas.style.width = "100%";
	threeCanvas.style.height = "100%";
	threeCanvas.style.zIndex = "1";
	threeCanvas.style.pointerEvents = "none"; // Allow clicks to pass through to PixiJS
	document.body.appendChild(threeCanvas);
	const threeRenderer = new WebGLRenderer({
		canvas: threeCanvas,
		alpha: true, // Transparent background
	});
	threeRenderer.setSize(window.innerWidth, window.innerHeight);
	threeRenderer.setPixelRatio(window.devicePixelRatio || 1);
	window.addEventListener("resize", () => {
		threeRenderer.setSize(window.innerWidth, window.innerHeight);
	});

	const scene = initScene();
	const camera = initCamera();

	function update() {
		requestAnimationFrame(update);
		updateScene(scene, camera);
		if (isDev) stats.begin();
		updateStats(threeRenderer);
		threeRenderer.render(scene, camera);
		if (isDev) stats.end();
	}
	update();
}
