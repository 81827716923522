import { Container, Sprite, Text } from "pixi.js";
import { ORIGINAL_HEIGHT, ORIGINAL_WIDTH } from "./config";
import { TextField } from "./TextField";
import TextStyles from "./textStyles";
/**
 * Template for the Cards, Map, Earn and Friends buttons.
 * @param iconName Name of the icon.
 * @param posX For moving it left/right.
 * @example
     const btnCards = new MenuButtonTemplate();
     this.addChild(btnCards);
     btnCards.initTemplate('Cards', -650);
 */
export class MenuButtonTemplate extends Container {
	private selected?: Sprite;
	private icon?: Sprite;
	private text?: TextField;
	constructor() {
		super();
	}

	initTemplate(iconName: string, posX: number, posXMenu: number) {
		this.selected = Sprite.from("selected");
		this.selected.visible = false;
		this.addChild(this.selected);

		this.icon = Sprite.from(iconName);
		this.addChild(this.icon);

		this.text = new TextField(
			iconName,
			202,
			60,
			TextStyles.LilitaOneRegular_50,
		);
		this.addChild(this.text);
		this.resize(posX, posXMenu);

		this.interactive = true; // Opt-in to interactivity
		this.cursor = "pointer";
	}

	turnOn() {
		if (this.selected) this.selected.visible = true;
	}
	turnOff() {
		if (this.selected) this.selected.visible = false;
	}

	resize(posX: number, posXMenu: number) {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (this.icon && this.text && this.selected) {
			this.icon.scale.set(scale, scale);
			this.icon.x = window.innerWidth / 2 + posX * scale;
			this.icon.y = window.innerHeight - this.icon.height - 120 * scale;

			this.selected.scale.set(scale, scale);
			this.selected.x = window.innerWidth / 2 + posXMenu * scale;
			this.selected.y =
				window.innerHeight - this.icon.height - 210 * scale;

			this.text.scale.set(scale, scale);
			this.text.x = this.icon.x - 30 * scale;
			this.text.y = this.icon.y + 140 * scale;
		}
	}
}
