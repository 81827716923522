import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import { Container, Sprite } from "pixi.js";
import { BottomDividers } from "./BottomDividers";
import { eventEmitter } from "@/PIXIcomponents";

export class BottomBackPanel extends Container {
	private backPanel?: Sprite;
	private backPanelStretch?: Sprite;
	private backPanelStretchRight?: Sprite;
	private dividers?: BottomDividers;
	private highlight?: Sprite;
	private _container?: Container;

	constructor() {
		super();
		this.initBackPanel();
		eventEmitter.on("openMenu", this.adaptMenuOpen.bind(this));
		eventEmitter.on("closeMenu", () => {
			this.adaptMenuOpen(true);
		});
	}

	initBackPanel() {
		this.backPanelStretch = Sprite.from("slice");
		this.addChild(this.backPanelStretch);
		this.backPanelStretchRight = Sprite.from("slice");
		this.addChild(this.backPanelStretchRight);

		this._container = new Container();
		this.addChild(this._container);

		this.backPanel = Sprite.from("base");
		this._container.addChild(this.backPanel);

		this.highlight = Sprite.from("Highlights");
		this.highlight.y = 20;
		this._container.addChild(this.highlight);

		this.dividers = new BottomDividers();
		this._container.addChild(this.dividers);

		this.resize();
	}

	adaptMenuOpen(closing: boolean = false) {
		if (this.backPanel && this.highlight) {
			this.backPanel.alpha = closing ? 1 : 0;
			this.highlight.alpha = closing ? 1 : 0;
		}
		this.resize();
	}

	resize() {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (
			this.backPanelStretch &&
			this.backPanelStretchRight &&
			this._container
		) {
			this._container.scale = scale;

			this._container.x =
				window.innerWidth / 2 - this._container.width / 2;
			this._container.y = window.innerHeight - this._container.height;

			this.backPanelStretch.scale.set(1, scale);
			this.backPanelStretch.width =
				window.innerWidth / 2 -
				(this.backPanel?.alpha === 1 ? this._container.width / 2 : 0);
			this.backPanelStretch.y =
				window.innerHeight - this.backPanelStretch.height;

			this.backPanelStretchRight.scale.set(1, scale);
			this.backPanelStretchRight.width =
				window.innerWidth / 2 -
				(this.backPanel?.alpha === 1 ? this._container.width / 2 : 0);

			this.backPanelStretchRight.x =
				window.innerWidth - this.backPanelStretchRight.width;
			this.backPanelStretchRight.y =
				window.innerHeight - this.backPanelStretch.height;
		}
	}
}
