import { eventHandlers } from "@/eventProcessor";
const eventMap = {
	"1": {
		type: "dice_rolled",
		timestamp: "2024-12-06T19:45:30.696129075Z",
		data: {
			rolled: 4,
			rolls_left: 25,
		},
	},
	"2": {
		type: "player_moved",
		timestamp: "2024-12-06T19:46:01.123456789Z",
		data: {
			from: {
				index: 4,
				tile_type: "bank",
			},
			to: {
				index: 11,
				tile_type: "coin",
			},
			wrapped: false,
		},
	},
	"3": {
		type: "coins_changed",
		timestamp: "2024-12-06T19:48:36.234706468Z",
		data: {
			amount: 200,
			reason: "player_raid",
			balance: 12300,
		},
	},
	4: {
		type: "jail_break_attempted",
		timestamp: "2024-12-06T19:49:00.234706468Z",
		data: {},
	},
	5: {
		type: "lap_award_granted",
		timestamp: "2024-12-06T19:49:01.234706468Z",
		data: {},
	},
	6: {
		type: "player_raided",
		timestamp: "2024-12-06T19:49:02.234706468Z",
		data: {},
	},
	7: {
		type: "shield_collected",
		timestamp: "2024-12-06T19:49:03.234706468Z",
		data: {},
	},
	8: {
		type: "shield_activated",
		timestamp: "2024-12-06T19:49:04.234706468Z",
		data: {},
	},
	9: {
		type: "shield_damaged",
		timestamp: "2024-12-06T19:49:05.234706468Z",
		data: {},
	},
	10: {
		type: "shield_expired",
		timestamp: "2024-12-06T19:49:06.234706468Z",
		data: {},
	},
	11: {
		type: "player_jailed",
		timestamp: "2024-12-06T19:49:07.234706468Z",
		data: {},
	},
	12: {
		type: "mentor_bonus",
		timestamp: "2024-12-06T19:49:08.234706468Z",
		data: {},
	},
	13: {
		type: "casino_bonus",
		timestamp: "2024-12-06T19:49:09.234706468Z",
		data: {},
	},
	14: {
		type: "rival_penalty",
		timestamp: "2024-12-06T19:49:10.234706468Z",
		data: {},
	},
	15: {
		type: "game_initialized",
		timestamp: "2024-12-06T19:49:11.234706468Z",
		data: {},
	},
};

export function initMocks() {
	document.addEventListener("keydown", (event) => {
		const keyNum = parseInt(event.key);
		if (
			keyNum >= 1 &&
			keyNum <= 15 &&
			eventMap[keyNum as keyof typeof eventMap]
		) {
			const eventData = eventMap[keyNum as keyof typeof eventMap];
			const handlers = eventHandlers[eventData.type];

			if (handlers?.length) {
				handlers.forEach((handler) => handler(eventData.data));
			} else {
				console.warn(`No handlers for event type: ${eventData.type}`);
			}
		}
	});
}
