import { Container } from "pixi.js";

export class CardsPage extends Container {
	constructor() {
		super();
		this.label = "cards";
		this.initCardsPage();
	}

	initCardsPage() {}

	showHide() {
		this.visible = !this.visible;
	}
}
