import { registerEventHandler } from "@/eventProcessor";
import { gameState, onGameUpdate } from "@/game";
import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import anime from "animejs";
import { Container, Graphics, Sprite } from "pixi.js";
import { TextField } from "../shared/TextField";
import TextStyles from "../shared/textStyles";
import { getTranslationTag } from "../shared/getTranslationTag";
import { action } from "@/api";

export class Jail extends Container {
	private _container?: Container;
	private backgroundFade?: Graphics;
	private jail?: Sprite;
	private btnLocked?: boolean;
	private jailNum?: TextField;
	private jailDesc2?: TextField;
	private rolledValue?: TextField;

	constructor() {
		super();
		this.initJail();
		this.on("RESIZE", this.resize.bind(this));
	}

	initJail() {
		this.backgroundFade = new Graphics()
			.rect(0, 0, 200, 200)
			.fill(0x000000);
		this.backgroundFade.alpha = 0.7;
		this.addChild(this.backgroundFade);

		this.jail = Sprite.from("jail");
		this.addChild(this.jail);

		this._container = new Container();
		this.addChild(this._container);
		const jailTitle = new TextField(
			getTranslationTag("jailTitle"),
			1000,
			120,
			TextStyles.LilitaOneRegular_Jail,
		);
		this._container.addChild(jailTitle);

		const jailDesc = new TextField(
			getTranslationTag("jailDesc"),
			1000,
			200,
			TextStyles.LilitaOneRegular_Jail,
		);
		jailDesc.y = 400;
		this._container.addChild(jailDesc);

		this.jailNum = new TextField(
			"5",
			200,
			200,
			TextStyles.LilitaOneRegular_Jail,
		);
		this.jailNum.x = 400;
		this.jailNum.y = 800;
		this._container.addChild(this.jailNum);

		this.jailDesc2 = new TextField(
			getTranslationTag("jailDesc2").replace("{num}", 5),
			1000,
			80,
			TextStyles.LilitaOneRegular_Jail,
		);
		this.jailDesc2.y = 1200;
		this._container.addChild(this.jailDesc2);

		const circle = new Graphics().circle(0, 0, 150).fill(0xffff00);
		circle.strokeStyle = {};
		circle.x = 500;
		circle.y = 1600;
		circle.alpha = 0.8;
		circle.interactive = true;
		circle.on("pointerdown", () => {
			this.rollDice();
		});
		this._container.addChild(circle);

		this.rolledValue = new TextField(
			"",
			200,
			200,
			TextStyles.LilitaOneRegular_Jail,
		);
		this.rolledValue.x = 400;
		this.rolledValue.y = 1500;
		this._container.addChild(this.rolledValue);

		this.alpha = 0;

		this.resize();

		onGameUpdate(() => {
			this.goInJail();
		});

		registerEventHandler("player_jailed", this.goInJail.bind(this) as any);

		registerEventHandler(
			"jail_break_attempted",
			async (e: {
				required_roll: number;
				rolled_value: number;
				successful: boolean;
			}): Promise<any> => {
				this.rolledValue?.setText(e.rolled_value + "");
				if (e.successful) {
					this.getOutOfJail();
				}
			},
		);
	}

	async rollDice() {
		if (this.btnLocked) return;

		this.btnLocked = true;
		await action("roll_dice");
		this.unlock();
	}
	unlock() {
		if (!this.btnLocked) return;

		this.btnLocked = false;
	}
	goInJail() {
		if (gameState.jailed > 0 && this.jailNum) {
			this.jailNum.setText(gameState.jailed + "");
			this.jailDesc2?.setText(
				getTranslationTag("jailDesc2").replace(
					"{num}",
					gameState.jailed,
				),
			);
			anime({
				targets: [this],
				alpha: 1,
				duration: 250,
				easing: "linear",
			});
		}
	}

	getOutOfJail() {
		anime({
			targets: [this],
			alpha: 0,
			duration: 250,
			easing: "linear",
		});
	}

	resize() {
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);

		if (this.backgroundFade && this.jail && this._container) {
			this.backgroundFade.width = window.innerWidth;
			this.backgroundFade.height = window.innerHeight;

			this.jail.width = window.innerWidth;
			this.jail.height = window.innerHeight;

			this._container.scale.set(scale);
			this._container.x = window.innerWidth / 2 - 500 * scale;
			this._container.y = window.innerHeight / 2 - 900 * scale;
		}
	}
}
