import { Container } from "pixi.js";
import { Coins } from "./Displays/Coins";
import { TopComponentPanel } from "./Displays/TopComponentPanel";
import { Shields } from "./Displays/Shields";
import { UserLevel } from "./Displays/UserLevel";

export class TopComponent extends Container {
	private backPanel?: TopComponentPanel;
	private coins?: Coins;
	private shields?: Shields;
	public userLevel?: UserLevel;

	constructor() {
		super();
		this.initTopComponent();
		this.on("RESIZE", this.resize.bind(this));
	}

	initTopComponent() {
		if (this.backPanel) {
			this.backPanel.destroy();
			this.removeChildren();
		}

		this.backPanel = new TopComponentPanel();
		this.addChild(this.backPanel);
		this.backPanel.initBackPanel();

		this.coins = new Coins();
		this.addChild(this.coins);
		this.coins.initCoins();

		this.shields = new Shields();
		this.addChild(this.shields);
		this.shields.initShields();

		this.userLevel = new UserLevel();
		this.addChild(this.userLevel);
		this.userLevel.initUserLevel();
	}

	getCoinsBalance() {
		return this.coins?.getCoinsBalance();
	}

	updateCoins() {
		this.coins?.updateCoins();
	}

	resize() {
		this.backPanel?.resize();
		this.coins?.resize();
		this.shields?.resize();
		this.userLevel?.resize();
	}
}
