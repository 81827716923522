import { Container, Sprite, Text } from "pixi.js";
import { gameState, onGameUpdate } from "@/game";
import TextStyles from "@/PIXIcomponents/shared/textStyles";
import {
	ORIGINAL_HEIGHT,
	ORIGINAL_WIDTH,
} from "@/PIXIcomponents/shared/config";
import { registerEventHandler } from "@/eventProcessor";
import { TextField } from "@/PIXIcomponents/shared/TextField";
import anime from "animejs";

export class Coins extends Container {
	private currentCoins?: number;
	private coinsValue?: TextField;
	private backPanel?: Sprite;
	private coinImage?: Sprite;
	constructor() {
		super();
	}

	initCoins() {
		this.backPanel = Sprite.from("CoinBase");
		this.addChild(this.backPanel);

		this.coinImage = Sprite.from("Coin");
		this.addChild(this.coinImage);

		this.coinsValue = new TextField(
			"0",
			400,
			80,
			TextStyles.LilitaOneRegular_70,
		);
		this.addChild(this.coinsValue);
		this.currentCoins = 0;
		anime({
			targets: [this.coinsValue._text],
			text: ["0", String(this.currentCoins)],
			easing: "linear",
			update: (a) => {
				const newVal: number = Number(
					parseFloat(a.animations[0].currentValue)
						.toFixed(2)
						.split(".")
						.join(""),
				);
				const displayValue = new Intl.NumberFormat("en", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
					notation: "compact",
				}).format(newVal);

				if (this.coinsValue) this.coinsValue._text.text = displayValue;
			},
		});

		onGameUpdate(() => {
			this.updateCoins();
		});

		registerEventHandler(
			"coins_changed",
			this.updateCoins.bind(this) as any,
		);
		this.resize();
	}

	resize() {
		// Scale display objects proportionally
		const scale = Math.min(
			window.innerWidth / ORIGINAL_WIDTH,
			window.innerHeight / ORIGINAL_HEIGHT,
		);
		if (this.backPanel && this.coinImage && this.coinsValue) {
			this.backPanel.scale.set(scale, scale);
			this.backPanel.x = window.innerWidth / 2 - this.backPanel.width / 2;
			this.backPanel.y = 360 * scale;

			this.coinImage.scale.set(scale, scale);
			this.coinImage.x = window.innerWidth / 2 - this.backPanel.width / 2;
			this.coinImage.y = this.backPanel.y + 5 * scale;

			this.coinsValue.scale.set(scale, scale);
			this.coinsValue.x = this.backPanel.x + 120 * scale;
			this.coinsValue.y = this.backPanel.y + 28 * scale;
		}
	}

	getCoinsBalance() {
		return this.currentCoins;
	}

	updateCoins() {
		const newCoins = gameState.coins;
		if (this.coinsValue && this.currentCoins !== newCoins) {
			const tmp = new Text({ text: this.currentCoins });
			anime({
				targets: [tmp],
				text: [String(this.currentCoins), String(newCoins)],
				easing: "linear",
				update: (a) => {
					const displayValue = new Intl.NumberFormat("en", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
						notation: "compact",
					}).format(Math.ceil(+a.animations[0].currentValue));

					if (this.coinsValue) this.coinsValue.setText(displayValue);
				},
				complete: () => {
					this.currentCoins = newCoins;
					tmp.destroy();
				},
			});
		}
	}
}
