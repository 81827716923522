import { Container } from "pixi.js";
import { Jail } from "./Jail";

export class Features extends Container {
	public jail?: Jail;

	constructor() {
		super();
		this.initFeatures();
	}

	async initFeatures() {
		this.jail = new Jail();
		this.addChild(this.jail);

		//OTHER FEATURES HERE
	}

	resize() {
		this.children.forEach((child) => {
			child.emit("RESIZE");
		});
	}
}
